<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Staff</h4>
            <div class="small text-muted">Adminsitración del staff</div>
          </b-col>
          <b-col sm="5">

            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Staff">
              <i class="fa fa-plus"></i> Agregar
            </b-button>

            <b-col sm="12" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>

          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">        
          <b-col sm="3" md="3">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese un nombre, teléfono o email" v-model="filters.name" v-on:keyup.enter.native="filterStaff"></b-form-input>              
              </b-input-group>
            </b-form-group>
          </b-col>                      
          <b-col sm="4" md="3">
            <b-button variant="outline-dark" @click="filterStaff()" size="sm" class="mt-1">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
              Filtrar
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table class="mb-0 table-staff-custom" 
                    ref="table"
                    responsive
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="true"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                                                                                

                <template v-slot:table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"                    
                    :key="field.key"
                    :style="{ width: field.width }"
                  >
                </template>    

                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                  </div>
                </template>

                <template v-slot:cell(id)="data">
                  <div>
                    <b>{{ data.item.id }}</b>
                  </div>
                </template>

                <template v-slot:cell(name)="data">                  
                  <b-button variant="link" @click="goStaffView(data.item)">
                    <b>{{data.item.name}}</b>
                  </b-button>                  
                </template>

                <template v-slot:cell(image)="data">
                  <b-avatar :src="data.item.image"
                            v-if="data.item.image">
                  </b-avatar>
                  <b-avatar v-else                  
                            variant="dark">
                  </b-avatar>                                  
                </template>

                <template v-slot:cell(f_acceso)="data">
                  <div v-if="data.item.user_id">
                    <b-link @click="viewAccess(data.item)" href="#">
                      <b>
                        Ver <b-icon icon="chevron-double-right"></b-icon>
                      </b>
                    </b-link>                                      
                  </div>
                  <div v-else>
                    <b-badge variant="danger">Sin Acceso</b-badge>
                  </div>
                </template>

                <template v-slot:cell(roles)="data">
                  <div v-if="data.item.user_id">
                    <div v-if="data.item.user.roles">
                      <b-badge  variant="secondary"
                                class="mr-2 badge-module-staff-custom"
                                v-for="value in filtroPersonal(data.item.user.roles)"
                                :key="value.id">
                        {{value.name}}
                      </b-badge>
                    </div>
                  </div>
                </template>

                <template v-slot:cell(observaciones)="data">                  
                  <div v-if="data.item.employee" class="staff-list-observaciones">
                    <b-icon icon="dot"></b-icon>Es Empleado
                  </div>
                  <div v-if="data.item.seller" class="staff-list-observaciones">
                    <b-icon icon="dot"></b-icon>Es Vendedor
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right 
                              text="Acción" 
                              size="sm" 
                              variant="outline-dark" 
                              class="pull-right"
                              @show="setConfigTableStaffShow"
                              @hide="setConfigTableStaffHide">
                    <b-dropdown-item @click="openObservationsInternals(data.item)" v-if="parameters.observationsInternals">
                      <b-icon icon="pencil-square"></b-icon> Notas
                    </b-dropdown-item>       

                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)" v-if="access.elements.editStaff">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deleteStaff">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>

                    <b-dropdown-header>Accesos</b-dropdown-header>
                    <b-dropdown-item @click="createUser(data.item)" v-if="!data.item.user_id">
                      <i class="fa fa-user-plus mr-0 ml-0" style="color:green"></i> Crear Usuario
                    </b-dropdown-item>
                    <b-dropdown-item @click="editRolesUser(data.item)" v-if="data.item.user_id">
                      <i class="fa fa-edit mr-0 ml-0" style="color:blue"></i> Editar Roles
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeUser(data.item)" v-if="data.item.user_id">
                      <i class="fa fa-user-times ml-0 mr-0" style="color:red"></i> Eliminar Usuario
                    </b-dropdown-item>

                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>
      <b-card header-tag="header" footer-tag="footer">
        <b-row class="mt-2">
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>          
          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterStaff()" />
            </nav>             
          </b-col>
        </b-row>

      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="5">
            <b-form-group label="Perfil">
              <Imagen :images="crud.form.image" @load-image="crud.form.image = $event" typeImage="Imagen"/>
            </b-form-group>  
          </b-col>
          <b-col md="7">
            <b-row>
              <b-col md="12">
                <b-form-group label="Nombre">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.name"
                                required
                                placeholder="Ingresar un nombre">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Email">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.email"
                                required
                                placeholder="Ingresar un email">
                  </b-form-input>
                </b-form-group>
              </b-col>               
              <b-col md="12">
                <b-form-group label="Teléfono" description="Cod país [sin el signo] + nueve [9] + cod área [sin el 0] + nro cel [sin el 15] (total 13 dígitos)">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.form.phone"
                                required>
                  </b-form-input>
                </b-form-group>
              </b-col> 
            </b-row>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD USER -->
      <b-modal v-model="modal.formUser.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formUser.title}}
        </div>

        <b-row>
          <b-col>
            <b-form-group label="Email">
              <b-form-input type="email"
                            size="sm"
                            v-model="crud.formUser.email"
                            required
                            placeholder="Ingresar un email">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Contraseña">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.formUser.password"
                            required
                            placeholder="Ingresar una contraseña">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Roles">
              <b-form-select v-model="crud.formUser.roles" :options="arr.roles" multiple :select-size="4"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formUser.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveUser()">Guardar</b-button>
        </div>
      </b-modal>

      <!-- FIND USER -->
      <b-modal v-model="modal.formUserRoles.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formUserRoles.title}}
        </div>

        <b-row>
          <b-col>
            <b-form-group label="Roles">
              <b-form-select v-model="crud.formUser.roles" :options="arr.roles" multiple :select-size="4"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formUserRoles.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveUserRoles()">Guardar</b-button>
        </div>
      </b-modal>

      <!-- PASSWORD -->
      <b-modal v-model="modal.password.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.password.title}}
        </div>

        <b-row>
          <b-col>
            
            <b-input-group class="w-100 mb-1">
              <b-input-group-append>
                <div class="input-group-text">
                  <b-icon class="mb-0" icon="person-fill"></b-icon>
                </div>
              </b-input-group-append>
              <b-form-input  type="text"
                            size="md"
                            v-model="crud.password.email"
                            readonly>
              </b-form-input>
            </b-input-group>

            <b-input-group class="w-100">                      
              <b-form-input :type="crud.formUser.typePassword"
                            size="md"
                            v-model="crud.password.password"
                            readonly>
              </b-form-input>
              <b-input-group-append>
                <b-button variant="secondary" size="sm" @click="changeTypePassword()">
                  <i class="icon material-icons">{{crud.formUser.iconPassword}}</i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="pull-right" @click="modal.password.active=false">Cerrar</b-button>          
        </div>
      </b-modal>
      
      <!-- OBSERVATIONS -->
      <b-modal v-model="modal.observations.active"
               header-bg-variant="dark"
               header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.observations.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group>
              <vue-editor v-model="crud.observations.observations_internal"></vue-editor>
            </b-form-group>                      
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.observations.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="setObservationsInternals()">Guardar</b-button>          
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Imagen from '@/components/inc/image/image'
  import Param from '@/config/parameters'
  import { VueEditor } from "vue2-editor";

  export default {
    components: {
      Imagen,
      VueEditor,
    },     
    data: () => {
      return {
        access: {
          module_id: Modules.PERSONAL,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crud',
          elements: {
            editStaff: true,
            deleteStaff: true
          }
        },
        parameters: {
          observationsInternals: Helper.hasParametersAccess(Param.P25),
        }, 
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle", width:"50px"},
            {key: 'image', label: 'Perfil', class:"align-middle", width:"50px"},
            {key: 'name', label: 'Nombre', class:"align-middle", width:"200px"},
            {key: 'phone', label: 'Teléfono', class:"align-middle", width:"150px"},
            {key: 'email', label: 'Email', class:"align-middle", width:"200px"},
            {key: 'f_acceso', label:'Acceso', class:"align-middle text-center", width:"80px"},
            {key: 'roles', label: 'Roles', class:"align-middle", width:"150px"},
            {key: 'observaciones', label: 'Observaciones', class:"align-middle", width:"200px"},
            {key: 'f_action', label:'', class:"align-middle", width:"100px"},
          ],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,            
        },
        arr : {
          roles: []
        },
        filters: {          
          name: '',          
        },        
        crud: {
          form: {
            id: 0,
            name: '',
            phone: '',
            email: '',
            user_id: 0,
            image: [],
          },
          formUser: {
            staff_id: 0,
            email: '',
            password: '',
            roles: [],
            typePassword: 'password',
            iconPassword: 'visibility',
          },
          password: {
            name: '',
            email: '',
            password: '',
          },
          observations: {
            staff_id: 0,
            observations_internal: '',
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          formUser: {
            active: false,
            title: ''
          },
          formUserRoles: {
            active: false,
            title: ''
          },
          password: {
            active: false,
            title: ''            
          },
          observations: {
            active: false,
            title: ''            
          }
        }
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.editStaff = Helper.hasAccessElement(this.access, 'edit_staff')
      this.access.elements.deleteStaff = Helper.hasAccessElement(this.access, 'delete_staff')      
      /* Fin configuracion */
    },
    mounted() {
      this.filterStaff()
      this.cargarRoles()
    },    
    methods: {
      setConfigTableStaffShow() {
        var arrTable = document.getElementsByClassName('table-staff-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '275px'
        }        
      },
      setConfigTableStaffHide() {
        var arrTable = document.getElementsByClassName('table-staff-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      }, 

      getRowCount (items) {
        return items.length
      },
      filterStaff () {
        this.table.isBusy = true  
        var result = serviceAPI.filtrarStaff(this.filters, this.table.currentPage)

        result.then((response) => {
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data

          this.crud.formUser.typePassword = 'text'
          this.changeTypePassword()

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }       

          this.table.isBusy = false
        })
        .catch(error => {
          this.table.isBusy = false
          this.$awn.alert(Error.showError(error))
        });
      },
      changeTypePassword() {
        if (this.crud.formUser.typePassword === 'password') {
          this.crud.formUser.typePassword = 'text'
          this.crud.formUser.iconPassword = 'visibility_off'
        } else {
          this.crud.formUser.typePassword = 'password'
          this.crud.formUser.iconPassword  = 'visibility'
        }
      },
      cargarRoles () {
        var result = serviceAPI.obtenerRoles()

        result.then((response) => {
          var data = response.data

          data.forEach(element => {
            if( element.profile_id == Profiles.PERSONAL ) {
              this.arr.roles.push({ value: element.id, text: element.name })
            }
          })

        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      filtroPersonal(row) {
        var arr = []
        row.forEach(element => {
          if(element.profile_id == Profiles.PERSONAL) {
            arr.push(element)
          }
        })
        return arr
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = ''
        this.crud.form.phone = ''
        this.crud.form.email = ''
        this.crud.form.user_id = 0
        this.crud.form.image = []
        this.modal.form.title = "Nuevo Staff"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.phone = (item.phone!=null) ? item.phone : ''
        this.crud.form.email = (item.email!=null) ? item.email : ''
        this.crud.form.user_id = item.user_id
        this.crud.form.image = item.image
        
        if(item.image.length>0) {
          this.crud.form.image = [{ path: item.image }]
        } else {
          this.crud.form.image = []
        }

        this.modal.form.title = "Editar Staff"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.user_id = 0

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Staff',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.delete()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        // valida que tenga el formato correcto para emails
        if(this.crud.form.email) {
          var expreg = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
          if (!expreg.test(this.crud.form.email)) {          
            this.$awn.alert("El email no posee un formato válido")          
            return false
          }       
        }

        // valida que tenga el formato para whatsapp
        if(this.crud.form.phone) {
          if(this.crud.form.phone.length < 9 || this.crud.form.phone.length > 20) {
            this.$awn.alert("El nro de télefono no posee un formato válido")
            return false
          }
        }

        let loader = this.$loading.show();

        var image = new FormData();
        this.crud.form.image.forEach((value, key) => {
          image.append(key, value.path);
        })

        if (this.crud.form.id) {
          var result = serviceAPI.editar(JSON.stringify(this.crud.form), image);
        } else {
          var result = serviceAPI.agregar(JSON.stringify(this.crud.form), image);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filterStaff()
          this.$awn.success("Datos guardados con éxito");

          if (!this.crud.form.id) {            
            this.goStaffView({
              id:response.data.id
            })
          }             
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      delete() {
        let loader = this.$loading.show();
        var result = serviceAPI.eliminar(this.crud.form.id);

        result.then((response) => {
          this.modal.formUser.active = false
          loader.hide()
          this.filterStaff()
          this.$awn.success("Registro eliminado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      createUser (item) {
        this.crud.formUser.staff_id = item.id
        this.crud.formUser.email = item.email
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []
        this.modal.formUser.title = "Asignar Usuario"
        this.modal.formUser.active = true
      },
      editRolesUser(item) {
        this.crud.formUser.staff_id = item.id
        this.crud.formUser.email = ''
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []

        if(item.user.roles) [
          item.user.roles.forEach(element => {
            this.crud.formUser.roles.push(element.id)
          })
        ]

        this.modal.formUserRoles.title = "Editar Roles Usuario --> " + item.name
        this.modal.formUserRoles.active = true
      },
      removeUser(item) {
        this.crud.formUser.staff_id = item.id
        this.crud.formUser.email = item.user.email
        this.crud.formUser.password = ''
        this.crud.formUser.roles = []

        this.$bvModal.msgBoxConfirm('¿Desea borrar el usuario (' + this.crud.formUser.email + ')?', {
          title: 'Borrar Usuario',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteUser()
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      saveUser() {
        let loader = this.$loading.show();
        var result = serviceAPI.crearUsuario(this.crud.formUser);

        result.then((response) => {
          this.modal.formUser.active = false
          loader.hide()
          this.filterStaff()
          this.$awn.success("Usuario creado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      saveUserRoles() {
        let loader = this.$loading.show();
        var result = serviceAPI.editarUsuarioRoles(this.crud.formUser);

        result.then((response) => {
          this.modal.formUserRoles.active = false
          loader.hide()
          this.filterStaff()
          this.$awn.success("Roles editados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      deleteUser() {
        let loader = this.$loading.show();
        var result = serviceAPI.eliminarUsuario(this.crud.formUser.staff_id);

        result.then((response) => {
          loader.hide()
          this.filterStaff()
          this.$awn.success(response.data);
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      openObservationsInternals(item) {
        this.crud.observations.staff_id = item.id
        this.crud.observations.observations_internal = item.observations_internal

        this.modal.observations.title = "Notas"
        this.modal.observations.active = true
      },
      setObservationsInternals() {
        let loader = this.$loading.show();
        var result = serviceAPI.setObservacionesInternas(this.crud.observations);

        result.then((response) => {
          this.modal.observations.active = false
          loader.hide()
          this.filterStaff()
          this.$awn.success("Observaciones guardadas con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      viewAccess(item){
        this.crud.password.name = item.name
        this.crud.password.email = item.user.email
        this.crud.password.password = item.user.password_visible
        
        this.modal.password.title = "Accesos --> " + this.crud.password.name
        this.modal.password.active = true
      },

      // Detalle view staff
      goStaffView(item) {
        this.$router.push({ name: 'StaffViewStaff', params: {staffID: item.id} })
      }      
    }
  }
</script>

<style>
  .badge-module-staff-custom {
    font-size: 14px;
  }
  .staff-list-observaciones {
    font-weight: 700;
    color: brown;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>